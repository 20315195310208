<template>
  <div>
    <h2 class="faledia-caption">Aufgaben und Darstellungen vernetzen</h2>
    <v-card outlined>
      <v-card-title>Was sollen die Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können...
        <ul>
          <li>
            Multiplikationsaufgaben flexibel von einer Darstellung in eine
            andere übertragen,
          </li>
          <li>
            Darstellungsmittel nutzen, um ihr Vorgehen beim Lösen einer Aufgabe
            zu beschreiben (und zu begründen) und
          </li>
          <li>erklären, wie sie eine Aufgabe berechnet haben</li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <p>
      Die Darstellung von Beziehungen und Strukturen beim kleinen Einmaleins
      sollte nicht nur auf symbolischer Ebene stattfinden, sondern immer auch
      mit Vorstellungen und Handlungen verknüpft sein. Dies ist zentral, um
      Beziehungen zwischen Aufgaben zu erkennen. So wird es Kindern bei der
      Betrachtung symbolischer Darstellungen nicht unbedingt ersichtlich sein,
      warum etwa 6 · 4 aus der Aufgabe 5 · 4 abgeleitet werden kann. Dies wird
      häufig erst dann greifbar, wenn die Aufgaben bildlich oder handelnd
      dargestellt werden (etwa durch das Verschieben von Plättchen, so dass die
      jeweils andere Aufgabe und die Veränderung ersichtlich wird).
    </p>
    <div class="flex">
      <div class="image">
        <AppExpandableImage
          contain
          :src="require('@/assets/eme/DreiVierer.png')"
          elementId="eme_dreivierer.png"
          parentId="eme"
        >
        </AppExpandableImage>
      </div>

      <p>
        Punktefelder spielen bei für die Multiplikation eine besondere Rolle in
        der Grundschulmathematik. Der Multiplikator, der Multiplikand und das
        Produkt werden konventionell so dargestellt, dass die Anzahl der Reihen
        dem Multiplikator entsprechen. Die Anzahl der Plättchen je Reihe
        entspricht dem Multiplikanden. In diesem Beispiel sind drei Reihen zu je
        vier Plättchen, insgesamt sind 12 Plättchen abgebildet.
      </p>
    </div>
    <p>
      Die folgende Abbildung visualisiert exemplarisch die verschiedenen
      Darstellungsebenen anhand der Aufgabenableitung zur Aufgabe 6 · 4 = 24 aus
      der Aufgabe 5 · 4 = 20. Die Pfeile verdeutlichen die Vernetzung zwischen
      den vier Darstellungsebenen.
    </p>
    <div class="bigImage">
      <AppExpandableImage
        contain
        :src="require('@/assets/eme/DWAbbildung.png')"
        elementId="eme_dwabbildung.png"
        parentId="eme"
      >
      </AppExpandableImage>
    </div>
    <p>
      Im Folgenden haben Sie nun die Gelegenheit, selbst einmal zu überlegen,
      wie verschiedene Aufgabenableitungen auf unterschiedlichen
      Darstellungsebenen aussehen können und welche die gleiche Ableitung
      darstellen.
    </p>
    <div style="background-color: white">
      <LernbausteinGruppen
        :id="LBSTGruppen[0].id"
        :checkAfterHowManyItems="LBSTGruppen[0].checkAfterHowManyItems"
        :checkAfterExactItemCount="LBSTGruppen[0].checkAfterExactItemCount"
        :showGroupNames="LBSTGruppen[0].showGroupNames"
        :workOrder="LBSTGruppen[0].workOrder"
        :possibleCategories="LBSTGruppen[0].possibleCategories"
        :elements="LBSTGruppen[0].elements"
        :responses="LBSTGruppen[0].responses"
      >
        <template v-slot:alternative-content>
          <v-simple-table class="imageTable">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-center no-wrap">
                    <strong>6 · 9</strong>
                  </td>
                  <td>
                    <div class="imageTableImages tableImageContainer">
                      <AppExpandableImage
                        :src="require('@/assets/eme/Gruppen/07.png')"
                        contain
                        parentId="SWVI"
                        elementId="SDWH2_10er/223_3.jpg"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="imageTableImages tableImageContainer">
                      <AppExpandableImage
                        :src="require('@/assets/eme/Gruppen/09.png')"
                        contain
                        parentId="SWVI"
                        elementId="SDWH2_10er/223_3.jpg"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="imageTableImages tableImageContainer">
                      <AppExpandableImage
                        :src="require('@/assets/eme/Gruppen/06.png')"
                        contain
                        parentId="SWVI"
                        elementId="SDWH2_10er/223_3.jpg"
                      />
                    </div>
                  </td>
                  <td>
                    Zunächst wird die Kernaufgabe 6 · 10 = 60 berechnet. Von
                    dieser (für viele Kinder) einfacheren Aufgabe ausgehend,
                    werden dann sechs Einer abgezogen, sodass es wieder sechs
                    Neuner sind, was der Ausgangsaufgabe entspricht.
                  </td>
                </tr>
                <tr>
                  <td class="text-center no-wrap">
                    <strong>9 · 6</strong>
                  </td>
                  <td>
                    <div class="imageTableImages tableImageContainer">
                      <AppExpandableImage
                        :src="require('@/assets/eme/Gruppen/04.png')"
                        contain
                        parentId="SWVI"
                        elementId="SDWH2_10er/223_3.jpg"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="imageTableImages tableImageContainer">
                      <AppExpandableImage
                        :src="require('@/assets/eme/Gruppen/01.png')"
                        contain
                        parentId="SWVI"
                        elementId="SDWH2_10er/223_3.jpg"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="imageTableImages tableImageContainer">
                      <AppExpandableImage
                        :src="require('@/assets/eme/Gruppen/12.png')"
                        contain
                        parentId="SWVI"
                        elementId="SDWH2_10er/223_3.jpg"
                      />
                    </div>
                  </td>
                  <td>
                    Zunächst wird die Kernaufgabe 10 · 6 = 60 berechnet. Von
                    dieser (für viele Kinder) einfacheren Aufgabe ausgehend,
                    wird dann ein Sechser abgezogen, sodass es wieder neun
                    Sechser sind, was der Ausgangsaufgabe entspricht.
                  </td>
                </tr>
                <tr>
                  <td class="text-center no-wrap">
                    <strong>8 · 4</strong>
                  </td>
                  <td>
                    <div class="imageTableImages tableImageContainer">
                      <AppExpandableImage
                        :src="require('@/assets/eme/Gruppen/11.png')"
                        contain
                        parentId="SWVI"
                        elementId="SDWH2_10er/223_3.jpg"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="imageTableImages tableImageContainer">
                      <AppExpandableImage
                        :src="require('@/assets/eme/Gruppen/05.png')"
                        contain
                        parentId="SWVI"
                        elementId="SDWH2_10er/223_3.jpg"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="imageTableImages tableImageContainer">
                      <AppExpandableImage
                        :src="require('@/assets/eme/Gruppen/08.png')"
                        contain
                        parentId="SWVI"
                        elementId="SDWH2_10er/223_3.jpg"
                      />
                    </div>
                  </td>
                  <td>
                    Zunächst wird die Kernaufgabe 8 · 5 = 40 berechnet. Von
                    dieser (für viele Kinder) einfacheren Aufgabe ausgehend,
                    werden dann acht Einer abgezogen, sodass es wieder acht
                    Vierer sind, was der Ausgangsaufgabe entspricht.
                  </td>
                </tr>
                <tr>
                  <td class="text-center no-wrap">
                    <strong>4 · 8</strong>
                  </td>
                  <td>
                    <div class="imageTableImages tableImageContainer">
                      <AppExpandableImage
                        :src="require('@/assets/eme/Gruppen/02.png')"
                        contain
                        parentId="SWVI"
                        elementId="SDWH2_10er/223_3.jpg"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="imageTableImages tableImageContainer">
                      <AppExpandableImage
                        :src="require('@/assets/eme/Gruppen/03.png')"
                        contain
                        parentId="SWVI"
                        elementId="SDWH2_10er/223_3.jpg"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="imageTableImages tableImageContainer">
                      <AppExpandableImage
                        :src="require('@/assets/eme/Gruppen/10.png')"
                        contain
                        parentId="SWVI"
                        elementId="SDWH2_10er/223_3.jpg"
                      />
                    </div>
                  </td>
                  <td>
                    Zunächst wird die Kernaufgabe 5 · 8 = 40 berechnet. Von
                    dieser (für viele Kinder) einfacheren Aufgabe ausgehend,
                    wird dann ein Achter abgezogen, sodass es wieder vier Achter
                    sind, was der Ausgangsaufgabe entspricht.
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </LernbausteinGruppen>
    </div>
    <AppLiteraturEME />
    <AppBottomNavEME
      back="/einmaleins/hintergrundwissen/ableitungsstrategien"
      next="/einmaleins/uebergang"
    />
  </div>
</template>

<script>
import AppBottomNavEME from "@/common/AppBottomNavEME";
import AppLiteraturEME from "@/common/AppLiteraturEME";
import AppExpandableImage from "@/common/AppExpandableImage";

import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LBSTGruppe from "@/components/faledia/seiten/einMalEins/LBST/LBST_Gruppen_DW";

export default {
  components: {
    AppBottomNavEME,
    AppLiteraturEME,
    AppExpandableImage,
    LernbausteinGruppen,
  },
  data: () => ({
    LBSTGruppen: [LBSTGruppe],
  }),
};
</script>

<style scoped>
.image {
  max-width: 20%;
  margin-inline-end: 5%;
}
.bigImage {
  max-width: 85%;
  margin-inline: auto;
}
.flex {
  display: flex;
  align-items: center;
  margin-block-end: 1%;
}
.imageTableImages {
  margin-top: 10px;
  margin-bottom: 10px;
}
.imageTable {
  margin-bottom: 20px;
}

.tableImageContainer {
  width: 179px;
}
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
</style>
