function createObject() {
  let example = {
    id: "LBST_Gruppen_1x1_DW",
    showGroupNames: true,
    checkAfterHowManyItems: 3,
    checkAfterExactItemCount: true,
    workOrder:
      "Nachfolgend sind vier Multiplikationsaufgaben in jeweils drei verschiedenen Darstellungsweisen abgebildet. Welche drei Darstellungsweisen stellen dieselbe Rechenaufgabe dar? Sortieren Sie in die vier Gruppen.",
    possibleCategories: [
      {
        id: 1,
        name: "6 · 9",
      },
      {
        id: 2,
        name: "9 · 6",
      },
      {
        id: 3,
        name: "8 · 4",
      },
      {
        id: 4,
        name: "4 · 8",
      },
    ],
    elements: [
      {
        id: 1,
        image: require("@/assets/eme/Gruppen/01.png"),
        category: 2,
        flex: 2,
      },
      {
        id: 2,
        image: require("@/assets/eme/Gruppen/02.png"),
        category: 4,
        flex: 2,
      },
      {
        id: 3,
        image: require("@/assets/eme/Gruppen/03.png"),
        category: 4,
        flex: 2,
      },
      {
        id: 4,
        image: require("@/assets/eme/Gruppen/04.png"),
        category: 2,
        flex: 2,
      },
      {
        id: 5,
        image: require("@/assets/eme/Gruppen/05.png"),
        category: 3,
        flex: 2,
      },
      {
        id: 6,
        image: require("@/assets/eme/Gruppen/06.png"),
        category: 1,
        flex: 2,
      },
      {
        id: 7,
        image: require("@/assets/eme/Gruppen/07.png"),
        category: 1,
        flex: 2,
      },
      {
        id: 8,
        image: require("@/assets/eme/Gruppen/08.png"),
        category: 3,
        flex: 2,
      },
      {
        id: 9,
        image: require("@/assets/eme/Gruppen/09.png"),
        category: 1,
        flex: 2,
      },
      {
        id: 10,
        image: require("@/assets/eme/Gruppen/10.png"),
        category: 4,
        flex: 2,
      },
      {
        id: 11,
        image: require("@/assets/eme/Gruppen/11.png"),
        category: 3,
        flex: 2,
      },
      {
        id: 12,
        image: require("@/assets/eme/Gruppen/12.png"),
        category: 2,
        flex: 2,
      },
    ],
  };

  const zweiRichtig =
    "Zwei der Darstellungen passen zur gleichen Aufgabe, eine Darstellung zeigt jedoch eine andere Aufgabe. ";
  const nichtPassend =
    "Ups, keins der eingeordneten Elemente stellt diese Aufgabe dar. Betrachten Sie die Anzahl der Gruppen (Multiplikator) und die Anzahl der Elemente je Gruppe (Multiplikand) noch einmal. Alle Darstellungen sollen zur gleichen Ausgangsaufgabe passen und zeigen jeweils die Ableitung über die gleiche Kernaufgabe in verschiedenen Darstellungsweisen. ";

  example.responses = [
    {
      id: "0003",
      category: example.possibleCategories[3],
      text:
        "Prima, die Darstellungen sind alle der richtigen Aufgabe zugeordnet.",
    },
    {
      id: "0030",
      category: example.possibleCategories[2],
      text:
        "Prima, die Darstellungen sind alle der richtigen Aufgabe zugeordnet.",
    },
    {
      id: "0300",
      category: example.possibleCategories[1],
      text:
        "Prima, die Darstellungen sind alle der richtigen Aufgabe zugeordnet.",
    },
    {
      id: "3000",
      category: example.possibleCategories[0],
      text:
        "Prima, die Darstellungen sind alle der richtigen Aufgabe zugeordnet.",
    },
    {
      id: "0012",
      text:
        "Zwei der Elemente zeigen die Ableitung der Aufgabe 4 · 8. Eins der Elemente zeigt die Ableitung der Tauschaufgabe 8 · 4. Schauen Sie sich die Anzahl der Gruppen und die Anzahl der Elemente je Gruppe noch einmal an. Wird der Multiplikator oder der Multiplikand verändert, um die Ausgangsaufgabe aus einer anderen Aufgabe abzuleiten?",
    },
    {
      id: "0102",
      text: zweiRichtig,
    },
    {
      id: "1002",
      text: zweiRichtig,
    },
    {
      id: "0021",
      text:
        "Zwei der Elemente zeigen die Ableitung der Aufgabe 8 · 4. Eins der Elemente zeigt die Ableitung der Tauschaufgabe 4 · 8. Schauen Sie sich die Anzahl der Gruppen und die Anzahl der Elemente je Gruppe noch einmal an. Wird der Multiplikator oder der Multiplikand verändert, um die Ausgangsaufgabe  aus einer anderen Aufgabe abzuleiten?",
    },
    {
      id: "0201",
      text: zweiRichtig,
    },
    {
      id: "2001",
      text: zweiRichtig,
    },
    {
      id: "0111",
      text: nichtPassend,
    },
    {
      id: "1011",
      text: nichtPassend,
    },
    {
      id: "1101",
      text: nichtPassend,
    },
    {
      id: "0120",
      text: zweiRichtig,
    },
    {
      id: "1020",
      text: zweiRichtig,
    },
    {
      id: "0210",
      text: zweiRichtig,
    },
    {
      id: "1200",
      text:
        "Zwei der Elemente zeigen die Ableitung der Aufgabe 9 · 6. Eins der Elemente zeigt die Ableitung der Tauschaufgabe 6 · 9. Schauen Sie sich die Anzahl der Gruppen und die Anzahl der Elemente je Gruppe noch einmal an. Wird der Multiplikator oder der Multiplikand verändert, um die Ausgangsaufgabe aus einer anderen Aufgabe abzuleiten?",
    },
    {
      id: "2100",
      text:
        "Zwei der Elemente zeigen die Ableitung der Aufgabe 6 · 9. Eins der Elemente zeigt die Ableitung der Tauschaufgabe 9 · 6. Schauen Sie sich die Anzahl der Gruppen und die Anzahl der Elemente je Gruppe noch einmal an. Wird der Multiplikator oder der Multiplikand verändert, um die Ausgangsaufgabe aus einer anderen Aufgabe abzuleiten?",
    },
    {
      id: "2010",
      text: zweiRichtig,
    },
    {
      id: "1110",
      text: nichtPassend,
    },
  ];

  return example;
}

export default createObject();
